import ConfirmModal from '../../../common/ConfirmModal'
import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { FormInput } from '../../forms/Form'
import "./AppliedFiltersDisplay.css";

function logicalOperatorDropdownOptions() {
    return [{"key":"OR", "title": "OR"},{"key":"AND", "title": "AND"}]
}

export default function AppliedFiltersDisplay( {appliedFilters, setAppliedFilters } ){

    const [ formState, setFormState ] = React.useState({"logicalOperator" : "AND"})

    //const [ modelActionComponent , setModelActionComponent ] = React.useState()
    const [showConfirmModal, setShowConfirmModal] = React.useState( false )
    const [removingFilter, setRemovingFilter] = React.useState()

    const removeAppliedFilter = ( item ) => {
        setAppliedFilters( appliedFilters => appliedFilters.filter( (filter) => (filter.disabled || filter.hidden || filter.column !== item.column ) ) )
    }

    // const handleShowFilterColumnsList = () => {
    //     setModelStateProp( true )
    //     setModelActionComponent( <FilterColumnsList  key={ makeUniqueId("filterColumns_") } displayProperties={ displayProperties } appliedFilters={appliedFilters} setAppliedFilters = {setAppliedFilters} setModalStateProp = {setModelStateProp} /> )
    // }

    return <div className="margin pb-1">
            <div className="">
                { /**
                <h2 class="heading"><span>Filter Group</span> <FormInput htmlID="logicalOperator" 
                                labelText=""
                                controlType={ "dropdown" } 
                                defaultValue={ formState['logicalOperator'] } 
                                dropdownOptions= { logicalOperatorDropdownOptions() }
                                setValue={ setFormState } formState={ formState }
                                selectClearable = { false }
                                overridePrarentClass="btn-group pl-1 pr-1 pb-1"
                                /> </h2>
                */}

            
        { appliedFilters.filter( item => typeof item.display === "undefined" || item.display ).map( (item,index) => {
            return <React.Fragment key={index}>
                    {/* { index !== 0 &&  <FormInput htmlID="logicalOperator" 
                                labelText=""
                                controlType={ "dropdown" } 
                                defaultValue={ formState['logicalOperator'] } 
                                dropdownOptions= { logicalOperatorDropdownOptions() }
                                setValue={ setFormState } formState={ formState }
                                selectClearable = { false }
                                overridePrarentClass="btn-group pl-1 pr-1 pb-1"
                                />  } */}
                
                    <div className="btn-group  pl-1 pr-1 pb-1">
                        <button type="button" className="btn btn-info" >
                            { item.column } { item.operator } { item.filterValue } { item.filterEndValue }
                        </button>
                        { (typeof item.disabled === undefined || !item.disabled ) && <button type="button" className="btn btn-info" onClick={(e) =>{
                            setShowConfirmModal( true )
                            setRemovingFilter( item )
                            e.preventDefault()
                        }}> <FaTrash /></button> }
                    </div>
                    </React.Fragment>
                }) }

                {/* TODO
                <div className="btn-group pl-1 pb-1">
                    <button type="button" className="btn btn-info" >
                        <FaFilter />
                    </button>
                </div> */}

                { showConfirmModal && <ConfirmModal modalStateProp={ showConfirmModal } setModalStateProp={ setShowConfirmModal } modalSuccessCallback={ ()=>{
                    removeAppliedFilter( removingFilter )
                }} modalSize={ "small" } /> }
        </div></div>
}