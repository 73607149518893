import { useState } from 'react';

const useToken = () => {
    const getToken = () => {
        const tokenString = localStorage.getItem('token')
        return tokenString
    };

    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
        localStorage.setItem('token', userToken)
        setToken(userToken)
    }

    return {
        token,
        saveToken
    }
}

export default useToken;