import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"
import { callSaveModelApi } from "../../services/ApiService";
import CardTools from "./common/CardTools";
import FormTemplate from "./common/FormTemplate";
import FormInput from "./common/FormInput";
import FormAccordianTemplate from "./common/FormAccordianTemplate";
import { ToastContainer, toast } from 'react-toastify';
import makeUniqueId from "../../hooks/useMakeUniqueId";

function Form( { entityID, pageConfig, pageTitle }) {

    const [ formConfig , setFormConfig ] = useState( pageConfig?.columnsConfig ? pageConfig.columnsConfig : [] )
    const [ apiUrl , setApiUrl ] = useState( pageConfig?.apiUrl ? pageConfig.apiUrl : "")
    const [ pageActions, setPageActions ] = useState( pageConfig?.pageActions ? pageConfig.pageActions : {} )
    const [ formEditing, setFormEditing ] = useState( typeof entityID === undefined )
    const [ formKey, setFormKey ] = useState("")
    
    //const navigate = useNavigate()
    const handleCancelAction = () => {
        //console.log( pageConfig.columnsConfig, "pageConfig.columnsConfig")
        //setFormConfig( pageConfig?.columnsConfig ? pageConfig.columnsConfig : [] )
        setFormKey( makeUniqueId() )
        setFormEditing( false )
    }

    const onSuccessCallback = ( response ) => {
        toast.success( response.message )

        //check if form config is returned
        if( response?.data?.columnsConfig ) {
            setFormConfig( response.data.columnsConfig )
        }
        setFormEditing( false )
    }

    return <> <ToastContainer />
                { formConfig.length && 
                <FormAccordianTemplate pageTitle={ pageTitle } type="form" 
                        cardTools={<CardTools pageActions={ pageActions } setFormEditing={ setFormEditing } formEditing={ formEditing } cancelEditAction={ handleCancelAction } /> }
                    
                        FormTemplate={ <FormTemplate key={ "form_" + formKey } columnsConfig={ formConfig } apiUrl={ apiUrl } onSuccessCallback={ onSuccessCallback } formEditing={ formEditing }
                                    />  } 
                    expandDefault={ true }
                /> }
    </>
}

export {FormInput, Form}