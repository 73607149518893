import * as React from "react";

const TopHeader = function(props) {
    
    return (
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
          <div className="container">
              {/* Brand Logo */}
            <a href="/" className="navbar-brand">
              <img src="./public/logo192.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: .8}} />
              <span className="brand-text font-weight-light">Brand Name</span>
            </a>
            
            {/* TODO: make menuBar component work in mobile view on click */}
            <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            
            <div>
              <a href="/logout"> Logout </a>
            </div>
          </div>
        </nav>
    )
}

export default TopHeader;