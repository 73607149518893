import { useEffect } from "react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
//import CreatableSelect from 'react-select/creatable';
import { callApiFromUrl } from "../../../services/ApiService";

export default function FormInput( { htmlID, labelText, controlType, formState, displayValue="", placeholderText = "", dropdownOptions, loadDropdownOptions = {}, className="", defaultValue = "", setValue, hide = false, hasError = false, errorMsg="", edit=true, multiSelect = false, selectClearable = true, overridePrarentClass = "form-group ", viewOnly=false, disabled = false, hidden = false, fileLink = "" }) {

    let fieldAttributes = {
        className : "form-control " + className + ( hasError ? " is-invalid" : ""),
        id : htmlID, 
        name: htmlID,
        placeholder: placeholderText,
        value: defaultValue,
        type: controlType,
        disabled: disabled,
        onChange: (event) => { changeInputValue( event.target.value ) }
    }

    const [ localDropdownOptions, setLocalDropdownOptions ] = useState( dropdownOptions ?? [] )

    useEffect(()=>{
        //load dropdown options from API if needed
        if( !localDropdownOptions.length && Object.keys(loadDropdownOptions).length && controlType === "dropdown" ) {
            //console.log(' load options from API ', labelText )
            callApiFromUrl( { apiUrl: loadDropdownOptions['apiUrl'], params: loadDropdownOptions['ddlConfig'], setErrors : (()=> console.log("Errors in loading options")), setSuccessResponse : (response) => {
                setLocalDropdownOptions( response.data )
                //console.log(labelText, "successfully loaded ", response.data )
            } } )
        }
    }, [] )

    //if control is dropdown, handle logic for setting value on state, and get state and set on select component
    if( controlType === "dropdown" ) {
        //override and remove class name for dropdown
        fieldAttributes['className'] = ""
        fieldAttributes['isSearchable'] = true
        fieldAttributes['isClearable'] = selectClearable
        fieldAttributes['isMulti'] = multiSelect === true

        fieldAttributes.onChange = ( newValue ) => { 
            //if selection was removed, set blank value on state
            if( newValue === null ) {
                changeInputValue( "" ) 
            } else {
                //if it's an array, prepare list and set on state
                if( Array.isArray( newValue ) ) {
                    const selectedInitialValue = []
                    for( let i = 0; i < newValue.length; i++ ) {
                        selectedInitialValue.push( newValue[i].value )
                    }
                    //console.log( selectedInitialValue.join(","),  " selectedInitialValue ")
                    changeInputValue( selectedInitialValue.join(","))
                } else if(  newValue?.value && newValue.value !== "" ) {
                    //for single selection, set value on state
                    changeInputValue( newValue.value ) 
                }
            }
        }

        //handle / format dropdown options
        if( localDropdownOptions.length ) {
            //console.log(" options found ", labelText )
            //remove default blank select option
            //transform and set dropdown options for input
            fieldAttributes['options'] = localDropdownOptions.filter( item => item.key !== "").map( item => { return { "value": item.key, "label" : item.title } } )

            //find current selected value(s) from dropdpwnOptions
            const findSelectedValue = fieldAttributes['options'].filter( (item) => {
                //if it's a multi select control, check value on list
                if( fieldAttributes['isMulti'] ) {
                    return fieldAttributes['value'].split(",").includes( item.value )
                } else {
                    //if single select, check value match
                    if( htmlID === "superAdmin") {
                        //console.log( defaultValue, fieldAttributes['value'], fieldAttributes['name'], item.value )
                    }
                    
                    return item.value === fieldAttributes['value'] || ( item.value == 0 && fieldAttributes['value'] === 0 )
                }
            })
            
            if( findSelectedValue.length ) {
                //console.log( findSelectedValue, "findSelectedValue")
                //if selected value is found in options list, set value for dropdown
                //if( fieldAttributes['value'] )
                fieldAttributes['value'] = findSelectedValue
            }
        } else {
            //console.log(" no options yet ", labelText)
        }

    }

    const changeInputValue = ( newValue ) => {
        //convert date object to string
        if( typeof newValue.getMonth === 'function' ) {
            newValue = newValue.getDay()+"-"+newValue.getMonth()+"-"+newValue.getYear()
        }

        setValue( {...formState, [htmlID] : newValue } )
    }

    
    if( controlType === "date" || controlType === "daterange" ) {
        let startDateObject = null
        let endDateObject = null
        fieldAttributes['isClearable'] = true
        fieldAttributes['dateFormat'] ="dd-MM-yyyy"
        //fieldAttributes['showMonthYearDropdown'] = true
        fieldAttributes['autoComplete'] = "off"
        fieldAttributes['showMonthDropdown'] = true
        fieldAttributes['showYearDropdown'] = true
        
        if( fieldAttributes['value'] !== "" ) {
            const checkDateRange = fieldAttributes['value'].split(" : ")
            const dateArray = checkDateRange[0].split("-")
            startDateObject = new Date(dateArray[2], dateArray[1] - 1, dateArray[0])
            if( checkDateRange.length > 1 ) {
                const endDateArray = checkDateRange[1].split("-")
                endDateObject = new Date(endDateArray[2], endDateArray[1] - 1, endDateArray[0])
            }
        }

        //set date object
        fieldAttributes['selected'] = startDateObject
        
        //date range 
        fieldAttributes['startDate'] = startDateObject
        fieldAttributes['endDate'] = endDateObject
        fieldAttributes['selectsRange'] = controlType === "daterange"
        

        fieldAttributes.onChange = ( date ) => { 
            //console.log( date, typeof date, typeof date[Symbol.iterator], " date picker change ")
            //typeof obj[Symbol.iterator] === 'function'
            
            let startDateValue = date;
            let endDateValue = null;
            if( typeof date[Symbol.iterator] === "function" ) {
                const [ startDate, endDate ] = date
                startDateValue = startDate;
                endDateValue = endDate;
            }
            
            if( startDateValue === null ) {
                changeInputValue( "" ) 
            } else {

                const formattedStartDate = startDateValue.getDate()+"-"+( startDateValue.getMonth() + 1 )+"-"+startDateValue.getFullYear();
                const formattedEndDate = endDateValue ? " : " + endDateValue.getDate()+"-"+( endDateValue.getMonth() + 1 )+"-"+endDateValue.getFullYear() : ""
                changeInputValue( formattedStartDate + formattedEndDate )
            }
        }
   
    }

    if( controlType === "checkbox" ) {
        //TODO: finish development
    }
    
    return <>
            { !hidden && <div className={ overridePrarentClass + " " + (hide ? "d-none" : "") }>
                
            { controlType !== "checkbox" ? <label htmlFor={ htmlID } className={ hasError ? "text-danger" : ""}>{ labelText }</label> : null }
            {edit && !viewOnly && {
                "text": <div className="form-group"><input {...fieldAttributes} /></div>,
                "password": <input {...fieldAttributes} />,
                "textarea": <textarea {...fieldAttributes}></textarea>,
                "dropdown": <div className="form-group"><Select {...fieldAttributes} /></div>,
                "radio": <>{ dropdownOptions && dropdownOptions.map( item => <div className="form-check" key={"radio"+ item.key }>
                    <input className="form-check-input" id={fieldAttributes.id+item.key} type="radio" value={item.key} name={ fieldAttributes.name } />
                    <label className="form-check-label" htmlFor={fieldAttributes.id+item.key}>{item.title}</label>
                </div> )}</>,
                "date": <div className="form-group"><DatePicker {...fieldAttributes} /> </div>,
                "daterange": <div className="form-group"><DatePicker {...fieldAttributes} /> </div>,
                // "checkbox" : <div className="form-check"> 
                //     <input className="form-check-input" type="checkbox" /> 
                //     <input {...fieldAttributes} />
                //     <label htmlFor={ htmlID } className={ hasError ? "text-danger" : ""}>{ labelText }</label>
                // </div>
            }[controlType]}
            { (!edit || viewOnly) && <p>{ fileLink ? <a href={ fileLink + displayValue } title="Download Sample" target="_blank">Download File</a> : ( displayValue ? displayValue : fieldAttributes.value) }</p>} 
            { hasError && <span className="error invalid-feedback" style={{ display: "block" }}>{errorMsg}</span>}
        </div> }
        </>
}