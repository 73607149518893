import { Link } from "react-router-dom";

export default function NotFoundLoginPage(){

    return (
            <div className="card-body">
                <p className="login-box-msg"> Invalid URL </p>

                <p className="mb-1 pt-2 text-center">
                    <Link to="/login"> Go to login </Link>
                </p>
            </div>
    )
}