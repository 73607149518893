import LoginRoutes from "../routes/LoginRoutes"
import PropTypes from 'prop-types';
import { useEffect } from "react";
import { appName } from "../constants/app";

export default function LoginLayout( {setToken} ){

    //set login template class on HTML body
    document.body.classList.add( "login-page" )
    document.body.classList.add( "hold-transition" )

    useEffect(() => { 

        //componentWillUnMount event
        return () => {
            document.body.classList.remove( "login-page" )
            document.body.classList.remove( "hold-transition" )
        }
    })

    return (
        <div className="login-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <h1> { appName }</h1>
                </div>
                <LoginRoutes setToken={setToken} />
            </div>
        </div>
    )
}

LoginLayout.propTypes = {
    setToken: PropTypes.func.isRequired
}