import TablePageLoader from "./TablePageLoader";

export default function DefaultPageTemplate( { loader = false, pageTitle = "Error", message = "", alertBootstrapClass = "warning" }) {

    return  <div className="content-wrapper">
                <section className="content" style={{ padding: "15px .5rem"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{ loader ? "Loading..." : pageTitle }</h3>
                                    </div>

                                    <div className="card-body">
                                        {!loader && <div className={ "alert alert-default-" + alertBootstrapClass } role="alert">
                                            { message }
                                        </div> }
                                        { loader && <TablePageLoader /> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
}