import { useState } from "react"

export default function DisplayErrorsTemplate( { errors } ) {
    let errorMessage = ""
    for(const [key, value] of Object.entries(errors)) {
        errorMessage += value+"\n"
    }
    
    return <>{ Object.keys(errors).length > 0 && <div className="alert alert-default-danger" role="alert" style={{whiteSpace: "pre-line"}}>
        {errorMessage}
    </div> }</>
}