import TablePagination from "./TablePagination"
import { useNavigate } from "react-router-dom"
import { useBatchSelection, BatchSelection } from "./common/BatchSelection"

import ConfirmModal from "../../common/ConfirmModal"
import { useState } from "react"
import { ListTemplate } from "./ListTemplate"
import { callGetPageConfigApi } from "../../services/ApiService"

function TableHeaderRow( {displayColumnsList, showViewColumn, applySorting, currentSortColumn = "taxRate", currentSortOrder = "ASC", selectableList = false } ) {

    return (
        <thead>
            <tr > 
                { (showViewColumn || selectableList ) && <th >
                    {
                        selectableList ? <BatchSelection /> : <></>
                    }
                </th> }
                { displayColumnsList.map( ( item, index ) => {
                    return <th key={index} className="sorting sorting_asc"> {item.title } 
                        <span className="ml-2 pt-1" style={{ fontSize :  "small" }}>
                            <i role="button" className={ "fa fa-arrow-down " + ( currentSortColumn === item.column && currentSortOrder === "ASC" ? "text-primary": " text-muted") } title="ASC" onClick={ ()=>{ applySorting( item.column, "ASC") }} aria-hidden="true"></i>
                            <i role="button" className={ "fa fa-arrow-up " + ( currentSortColumn === item.column && currentSortOrder === "DESC" ? "text-primary": "text-muted") } title="DESC" onClick={ ()=>{ applySorting( item.column, "DESC") }} aria-hidden="true"></i>
                        </span>
                    
                    </th>
                }) }
            </tr>
        </thead>
    )
}

function TableBodyRow( { data, displayColumnsList, entityName, primaryColumn, showViewColumn, selectableList, onViewClickCallback, pageGroupBy = [] } ) {

    const primaryID = primaryColumn ? data[primaryColumn.column] : ""

    const queryParam = primaryID !== "" ? primaryID : ( pageGroupBy.length > 0 ? pageGroupBy.map( item => item+"|"+data[item] ).join(",") : "" )

    return (
        <tr>
            { ( showViewColumn || selectableList ) && <td >
                <span style={{ display: "flex"}}>
                { selectableList ? <BatchSelection selectedValue={ primaryID } /> : <></> }
                { showViewColumn && <a style={{ cursor: 'pointer'}} onClick={
                    (  ) => {
                        return onViewClickCallback(queryParam)
                    }
                    }>
                    <i className="fa fa-eye"></i>
                </a>}
                </span>
            </td> }
            { displayColumnsList.map( ( item, index ) => {
                
                //cherry pick the relational column value, if name contains dot
                let columnValue = ""
                if( item.column.includes(".") ) {
                    let relational = item.column.split(".")
                    //console.log( data[ relational[0] ], "check for null")
                    if( relational[0] in data && ( data[ relational[0] ] != null && relational[1] in data[ relational[0] ] ) ) {
                        columnValue = data[ relational[0] ][ relational[1]]
                    }
                } else {
                    columnValue = data[ item.column ]
                }

                return <td key={ "tbd" + index } style={ item?.formatType === "number" ? { textAlign: "right"} : {whiteSpace: 'pre-line'} }> { columnValue } </td>
            }) }
        </tr>
    )
}

function TableBody( {displayColumnsList, pageRecords, entityName, primaryColumn, showViewColumn, selectableList, onViewClickCallback, pageGroupBy = [] } ) {
    /** Error Handling needed if column name doesn't exist in pageRecords Data */

    return (
        <tbody>
            { pageRecords.map( (item,index)=> {
                return <TableBodyRow primaryColumn={primaryColumn} showViewColumn={showViewColumn} selectableList={selectableList} entityName={entityName} data = { item } key={ "tbr" + index } displayColumnsList = { displayColumnsList } onViewClickCallback = { onViewClickCallback } pageGroupBy = { pageGroupBy } />
            }) }

            { pageRecords.length === 0 &&
            <tr > 
                <td colSpan={ displayColumnsList.length + 1 } className="center">
                No Records
                </td>
            </tr>
            }
        </tbody>
    )
}

function TableFooter( { displayColumnsList, displayColumnsCount, paginator = {}, handlePageChange, pageAggregates, showViewColumn } ) {
    
    return (
        <tfoot>
            { pageAggregates.length > 0 && <tr>
                { showViewColumn && <td></td> }
                { displayColumnsList.map( ( item, index ) => {
                    const showAggregate = pageAggregates.filter( filterItem => {
                        return filterItem.column === item.column 
                    })
                    return showAggregate.length ? <td key={"aggregateFooter" + index}> { showAggregate[0].title } : { showAggregate[0].value } </td> : <td key={"aggregateFooter" + index}></td>
                }) }
            </tr> }
            <tr >
                <td colSpan={ displayColumnsCount + ( showViewColumn ? 1 : 0 )  }> 
                    <TablePagination paginator = { paginator } handlePageChange = { handlePageChange } />
                </td>
            </tr>
        </tfoot>
    )
}


export default function Table( { displayProperties, pageRecords, pageAggregates = [], paginator = {}, handlePageChange, entityName, handleSortChange, sortColumn = "", sortOrder = "", selectableList = false, hideViewColumn = false, viewByConfig = {}, viewByApi = "", pageGroupBy = [] } ) {

    //sort display properties
    const displayColumnsList = displayProperties.sort( (first, second) => first.sortOrder - second.sortOrder ).filter( item => item.display && !item.hidden )
    const primaryColumn = displayProperties.find( item => item.isPrimary )
    
    //TODO: do better job
    const showViewColumn = hideViewColumn ? false : primaryColumn !== undefined ? true : ( selectableList ? true : ( Object.keys(viewByConfig).length ? true : ( viewByApi !== "" ? true : false ) ) )

    //console.log( showViewColumn, " showViewColumn ", viewByApi !== "" , " selectableList ", viewByApi)

    const { bulkSelectedRecords } = useBatchSelection()
    
    const [showConfirmModal, setShowConfirmModal] = useState( false )
    const [modalTitle, setModalTitle ] = useState("")
    const [ modalBodyComponent, setModalBodyComponent ] = useState("")
    const navigate = useNavigate()
    const onViewClickCallback = async ( recordID ) => {
        if( Object.keys(viewByConfig).length ) {
            setShowConfirmModal( true )
            setModalTitle( viewByConfig['pageTitle'] )
            setModalBodyComponent( <ListTemplate entity={ entityName } 
            pageTitle={ viewByConfig['pageTitle'] }
            pageConfig={ viewByConfig['pageConfig'] }
            /> )
            //console.log( viewByConfig, " view by config is here ", recordID, "reacord ID")
        } else if( viewByApi !== "") {
            //call API and load component
            await callGetPageConfigApi({entityName: entityName, pageAction : viewByApi, entityID : recordID, setErrors : (error) => {
                if( Object.keys(error).length > 0 ) {
                    console.log("error in loading page from API ", error )
                }
            }, setSuccessResponse : ( response ) => {
                
                //console.log( " new view page config ", response.data.pageTabs[0].pageConfig )

                setShowConfirmModal( true )
                setModalTitle( response.data.pageTabs[0].pageTitle )
                setModalBodyComponent( <ListTemplate entity={ response.data.pageTabs[0].entity } 
                    pageTitle={ response.data.pageTabs[0].pageTitle }
                    pageConfig={ response.data.pageTabs[0].pageConfig }
                    key={ recordID }
                    /> )
            } })

        } else {
            navigate("/"+entityName+"/view/"+recordID)
        }
    }

    

    return (
        <div className="table-responsive" style={{ "whiteSpace": "nowrap"}}>
            { selectableList && <div>Total Selected: { bulkSelectedRecords.length } </div> }
            <table className="table table-bordered table-hover">
                <TableHeaderRow displayColumnsList={ displayColumnsList } showViewColumn={ showViewColumn } applySorting={ handleSortChange } currentSortColumn = { sortColumn } currentSortOrder = { sortOrder } selectableList = { selectableList } />
                <TableBody displayColumnsList={ displayColumnsList } pageRecords={ pageRecords } entityName={entityName} primaryColumn={primaryColumn}  showViewColumn={ showViewColumn }  selectableList = { selectableList } onViewClickCallback = { onViewClickCallback } pageGroupBy={ pageGroupBy } />
                <TableFooter displayColumnsList={ displayColumnsList } showViewColumn={ showViewColumn } pageAggregates={ pageAggregates } displayColumnsCount={ displayColumnsList.length } paginator = { paginator } handlePageChange = { handlePageChange } />
            </table>

            { showConfirmModal && <ConfirmModal modalStateProp={ showConfirmModal } modalTitle={modalTitle} setModalStateProp={ setShowConfirmModal } modalSize={ "xLarge" } modalBodyComponent={ modalBodyComponent } hideFooter={ true } />}
        </div>
    )
}