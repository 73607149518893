
import LoginLayout from './layout/LoginLayout';
import AdminPanelLayout from './layout/AdminPanelLayout';
import useToken from './hooks/useToken';
import './App.css';
import { appName } from './constants/app';
//import FullPageLoader from './bela/Loader/FullPageLoader/FullPageLoader';

function App() {
  const {token, saveToken} = useToken()
  
  //set page title
  document.title = appName;

  //return <FullPageLoader />

  return (
    <>
    { !token && <LoginLayout setToken={saveToken} /> }
    { token &&  <AdminPanelLayout  setToken={saveToken} /> }
    </>
  )
}

export default App;
