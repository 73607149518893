import * as React from "react";
import useHeaderHook from "./HeaderHook";

const Header = function() {
    
    const { showSideBar, toggleSideBar, logout, userName, userID } = useHeaderHook()
    
    if( showSideBar ) {
      document.body.classList.add( "sidebar-collapse" )// 
      document.body.classList.add( "sidebar-open" )// 
      document.body.classList.remove( "sidebar-closed" )// 
    }
    else{
      document.body.classList.remove( "sidebar-collapse" )// 
      document.body.classList.remove( "sidebar-open" )// 
      document.body.classList.add( "sidebar-closed" )// 
    }
    
    const handleLogout = ( e ) => {
      e.preventDefault()
      logout()
    }
    
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" onClick={ toggleSideBar} data-widget="pushmenu" href="/" role="button"><i className="fas fa-bars"></i></a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href={ "/account/view/" + userID } role="button">{ userName }</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={handleLogout} href="/" role="button">
                <i className="fas fa-sign-out-alt"></i> Logout
              </a>
            </li>
          </ul>

        </nav>
    )
}

export default Header;