import { Routes, Route } from 'react-router-dom'
import ForgotPassword from '../pages/postAuthPages/ForgotPassword'
import Login from '../pages/postAuthPages/Login'
import ResetPassword from '../pages/postAuthPages/ResetPassword'
import PropTypes from 'prop-types';
import NotFoundLoginPage from '../pages/NotFound/NotFoundLoginPage'


export default function LoginRoutes( {setToken}) {
    return (
        <Routes>
            <Route path="/" element={ <Login setToken={setToken} /> } />
            <Route path="/login" element={ <Login setToken={setToken} /> } />
            <Route path="/forgotPassword" element={ <ForgotPassword /> } />
            <Route path="/resetPassword/:resetToken" element={ <ResetPassword /> } />
            <Route path="*" element={ <NotFoundLoginPage /> } />
        </Routes>
    )
}

LoginRoutes.propTypes = {
    setToken: PropTypes.func.isRequired
}