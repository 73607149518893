import { useState } from 'react';

export default function useMenu() {
    const getMenuList = () => {
        const menuString = localStorage.getItem('appMenu')
        return JSON.parse( menuString )
    };

    const [menuList, setMenuList] = useState(getMenuList())

    const saveMenuList = menuList => {
        if( typeof menuList !== "object") {
            alert("useMenu is expecting an Object.")
            return
        }
        localStorage.setItem('appMenu', JSON.stringify( menuList) )
        setMenuList(menuList)
    }

    return {
        menuList,
        saveMenuList
    }
}