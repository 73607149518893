import Table from "./Table"
import ListActionBar from "./ListActionBar"
import { useEffect, useState, useRef } from "react"
import AppliedFiltersDisplay from "./common/AppliedFiltersDisplay"
import React from 'react'
import { callListModelApi } from "../../services/ApiService"
import DefaultPageTemplate from "../../common/pages/list/DefaultPageTemplate"
import TablePageLoader from "../../common/pages/list/TablePageLoader"
import { ListPageTableSection } from "./common/ListPageTableSection"

import { BatchSelectionProvider, useBatchSelection } from "./common/BatchSelection"

export function ListTemplate( { entity, pageConfig, onlyTableBody = false }) {
  //return
  //const { cursor } = useListPageHook(entity)
  const [errors, setErrors] = useState({})
  const [ loader, setLoader] = useState(false)
  
  const [ pageRecords , setPageRecords ] = useState( pageConfig?.records?.pageRecords ? pageConfig.records.pageRecords : [] )
  const [ pageAggregates , setPageAggregates ] = useState( pageConfig?.records?.aggregates ? pageConfig.records.aggregates : [] )
  
  
  //get entity config from API
  const [ propertiesConfig, setPropertiesConfig ] = useState( pageConfig?.columnsConfig ? pageConfig.columnsConfig : [] )
  
  const [ pageGroupBy, setPageGroupBy ] = useState( pageConfig?.groupBy ? pageConfig.groupBy : [] )

  //records per page
  const [ recordsPerPage, setRecordsPerPage ] = useState( pageConfig?.records?.recordsPerPage ? pageConfig.records.recordsPerPage : 10 )
  const [ displayPropertiesChanged, setDisplayPropertiesChanged ] = useState(false)
  //applied filters
  const [appliedFilters, setAppliedFilters ] = useState( pageConfig?.listActions?.filters ? pageConfig.listActions.filters : [] )
  
  //sort operators
  const [ sortColumn, setSortColumn ] = useState( pageConfig?.sortColumn ? pageConfig.sortColumn : "" )
  const [ sortOrder, setSortOrder ] = useState( pageConfig?.sortOrder ? pageConfig.sortOrder : "" )
  const [ sortApplied, setSortApplied ] = useState(false)
  const [ paginationAttributes, setPaginationAttributes ] = useState( pageConfig?.records?.paginationAttributes ? pageConfig.records.paginationAttributes : {} )
  const [ listActions, ] = useState( pageConfig?.listActions ? pageConfig.listActions : [] )
  const [ paginationType, ] = useState( pageConfig?.paginationType ?? "cursor" )
  const firstUpdate = useRef(true)

  
  const [ selectableList,  ] = useState( pageConfig?.listActions?.selectBulkActions?.length > 0 )
  const [ hideViewColumn ] = useState( pageConfig?.tableConfig?.hideViewColumn === true ?? false )
  const [ viewByConfig ]  = useState( pageConfig?.tableConfig?.loadViewConfig )
  const [ viewByApi ] = useState( pageConfig?.tableConfig?.loadViewFromApi ?? "" )

  //const [ tableButtons ] = useState( { } )


  const handlePageChange = ( cursor ) => {
    callListApi(cursor)
  }

  const handleSortChange = ( sortColumn, sortOrder) => {
    //console.log( sortOrder, sortColumn , "handleSortChange")
    setSortOrder( sortOrder )
    setSortColumn( sortColumn )
    setSortApplied( !sortApplied )
  }
  const setSuccessResponse = ( response ) =>{
    if( response?.data ) {
      response = response.data
    }

    if( response?.pageRecords) {
      setPageRecords( response.pageRecords )
    }

    if( response?.pageRecords) {
      setPageAggregates( response.aggregates )
    }
    
    setPaginationAttributes( response.paginationAttributes )
  }

  //handle if some display properties got toggled
  const handleDisplayPropertiesChange = ( propertiesConfig, recordsPerPage ) => {
    setPropertiesConfig( propertiesConfig )
    setRecordsPerPage( recordsPerPage )
    setDisplayPropertiesChanged( !displayPropertiesChanged )
  }

  const callListApi = async ( cursor = "" ) => {
    if( loader )
      return;
    setLoader( true )

    const displayPropertiesFromConfig = propertiesConfig.filter( item => item.isPrimary || (item.display && item.aggregate === "") ).map( item => item.column )

    const appliedGroupBy = pageGroupBy.length ? displayPropertiesFromConfig.filter( item => !item.includes(".") ) : []

    setPageGroupBy( appliedGroupBy )
    const serviceArgs = {
        displayProperties: displayPropertiesFromConfig, //array of display = true column names
        pageCursor : cursor,
        sortOrder: sortOrder ,
        sortColumn: sortColumn ,
        aggregates: pageConfig?.aggregates || [],
        filters: appliedFilters,
        recordsPerPage: recordsPerPage,
        paginationType: paginationType,
        recordLevelAggregates : propertiesConfig.filter( item => item.display && item.aggregate !== "" ).map( item => {
          return { 
            column: item.column,
            aggregate: item.aggregate
          }
        } ),
        groupBy: appliedGroupBy
    }
    
    const response = await callListModelApi( entity, serviceArgs, setErrors, setSuccessResponse )
    setLoader( false )
  }

  const [ primaryColumn, setPrimaryColumn ] = useState("")
  useEffect( () => {
    const primaryProperty = propertiesConfig.find( item => item.isPrimary )
    if( primaryProperty !== undefined ) {
      setPrimaryColumn( primaryProperty.column )
    }
    
  }, [ propertiesConfig ])

  //call api using state
  useEffect( () => {

    if( firstUpdate.current && pageConfig?.records?.pageRecords ) {
      firstUpdate.current = false;
      return;
    }
    
    callListApi()
  }, [displayPropertiesChanged, appliedFilters, sortApplied ])


  const PageCardBody = () => {
   return <ListPageCardBody appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loader={loader}
      entity={entity}
      handlePageChange={handlePageChange}
      paginationAttributes={paginationAttributes}
      displayProperties={propertiesConfig}
      pageRecords={pageRecords}
      pageAggregates={ pageAggregates}
      handleSortChange={ handleSortChange }
      sortColumn={ sortColumn }
      sortOrder={ sortOrder }
      selectableList={ selectableList }
      hideViewColumn={ hideViewColumn }
      viewByConfig={ viewByConfig }
      viewByApi= { viewByApi }
      pageGroupBy={ pageGroupBy }
    />
  }

  if( onlyTableBody ) return <PageCardBody />

    return (<>
              { /* Object.keys(errors).length > 0 && <div class="alert alert-default-danger" role="alert">
                  There are some errors in processing this request
              </div> */ }

              { !Object.keys(errors).length && propertiesConfig.length > 0 && 
              
              <BatchSelectionProvider propertyName={ primaryColumn } pageRecords={ pageRecords } >
                <ListPageTableSection
                      entity = { entity } 
                      listActionBar= {
                        <ListActionBar
                          listActions = { listActions } 
                          displayProperties={ propertiesConfig } 
                          handleDisplayPropertiesChange= { handleDisplayPropertiesChange } 
                          recordsPerPage={ recordsPerPage }
                          appliedFilters={appliedFilters} 
                          setAppliedFilters={ setAppliedFilters } 
                          refreshList={callListApi} 
                          entity={entity}
                          sortColumn={ sortColumn }
                          sortOrder={ sortOrder }
                          pageGroupBy={ pageGroupBy }
                          />
                      }
                      showCreateButton={ "create" in listActions }
                      createFormConfig={ "create" in listActions ? listActions["create"] : {} }
                      refreshList={callListApi}
                      >
                        <PageCardBody />
                </ListPageTableSection>
              </BatchSelectionProvider> }
            </>
    )
}

export function ListPageCardBody( { appliedFilters, pageAggregates, setAppliedFilters, loader, entity, handlePageChange, paginationAttributes, displayProperties, pageRecords, handleSortChange, sortColumn, sortOrder, selectableList, hideViewColumn, viewByConfig, viewByApi, pageGroupBy } ) {
  
  return  <>{ appliedFilters.length > 0 && <AppliedFiltersDisplay appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} />}
  { loader && <TablePageLoader /> }
  { !loader && <Table pageAggregates = {pageAggregates } entityName={ entity } handlePageChange = { handlePageChange } paginator={ paginationAttributes } displayProperties={ displayProperties }  pageRecords = { pageRecords } handleSortChange={ handleSortChange } sortColumn = { sortColumn } sortOrder = { sortOrder } selectableList = { selectableList } hideViewColumn = { hideViewColumn } viewByConfig = { viewByConfig } viewByApi = { viewByApi } pageGroupBy={ pageGroupBy } /> }
  </>
}