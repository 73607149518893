import TopHeader from "../common/TopHeader";
import SideBarHeader from "../common/SideBarHeader";
import {MenuBar} from "../common/MenuBar";
import Footer from "../common/Footer";
import WebRoutes from "../routes/WebRoutes";
import { useMemo } from "react";

export default function AdminPanelLayout( { setToken } ) {

    const layoutType = "sidebar"; // "topbar", "sidebar"
    //const [ loader, setLoader] = useState( false )
    
    //set app template class on HTML body
    document.body.classList.add( "hold-transition" )
    document.body.classList.add( layoutType === "topbar" ? "layout-top-nav" : "sidebar-mini" )

    //useMemo
    useMemo(async () => { 

        //componentWillUnMount event
        return () => {
            document.body.classList.remove( layoutType === "topbar" ? "layout-top-nav" : "sidebar-mini"  )
            document.body.classList.remove( "hold-transition" )
        }
    }, [layoutType])

    return <div className="wrapper">
                {/* Start Main Wrapper*/}
                { layoutType === "topbar" ? <TopHeader /> : <SideBarHeader setToken={setToken}/> }

                <MenuBar layoutType={ layoutType } />
                
                <WebRoutes />
                
                <Footer />

            </div>

}

//#vsbQjLq5PthjDyT76Aka