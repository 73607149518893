import FormInput from "./FormInput"
import React from 'react'
import { callApiFromUrl } from "../../../services/ApiService"

const RenderFormInput = ({ item, formState, errors, formEditing, setFormState }) => {
    return <FormInput htmlID={item.column}
    labelText={item.title} 
    placeholderText={item.title} 
    controlType={item.fieldType} 
    className={ item.fieldType==="dropdown" ? "" : "" } 
    dropdownOptions={ item.fieldType === "dropdown" || item.fieldType === "radio" ? item.dropdownOptions : []}
    loadDropdownOptions={ item.fieldType === "dropdown" && !item.dropdownOptions.length ? item?.loadDropdownOptions : {} }
    hasError={ item.column in errors}
    defaultValue={ formState[item.column] }
    errorMsg={ item.column in errors ? errors[item.column] : ""}
    edit={formEditing}
    formState={ formState }
    setValue={ setFormState }
    displayValue={ item?.displayValue}
    multiSelect={ item?.multiSelect && item.multiSelect === true }
    viewOnly={ item?.viewOnly }
    disabled={ false }
    hidden={ !item.display }
    fileLink={ item?.fileLink }
    />
}

function FormTemplate( { successMsg = "", columnsConfig, formEditing, errors, loader, handleFormSubmit, apiUrl, onSuccessCallback } ) {
    
    const processColumnRules = ( itemToEvaluate ) => {
        //make a copy of item
        let item = JSON.parse(JSON.stringify(itemToEvaluate))
        //check if rules are defined on item
        if( item?.rules && item.rules.length ) {
            
            for( let i = 0; i < item.rules.length; i++ ) {
                //get qualifires - an array
                const qualifiers = item.rules[i]['qualifires']
                //loop over qualifiers
                for( let key in qualifiers ) {
                    //check of current form state value qualifies 
                    if( formState[key] === qualifiers[ key ] ) {
                        //apply effect if rule qualified
                        const effect = item.rules[i]['effect']
                        for( let effectKey in effect ) {
                            item[effectKey] = effect[effectKey]
                            //console.log( "rule qualified and modified attribute "+ effectKey +" to " + effect[effectKey] )
                        }
                        
                    } //we don't need reset since we're accepting original config in args
                }
            }
        }
        
        return item
    }

    const getUpdatedColummnsConfig = ( schema ) => {
        return schema.map( item =>  processColumnRules( item ) )
    }

    const initFormSchema = ( schema ) => {
        let formSchema = {}
        schema.map( item => {
            formSchema[item.column] = item.value
            return item
        })
        return formSchema
    }
    
    const [ formState, setFormState ] = React.useState( initFormSchema( columnsConfig ) )
    const [ formDislayColumns, setFormDisplayColumns ] = React.useState( getUpdatedColummnsConfig( columnsConfig ) )
    
    React.useEffect( ()=>{
        setFormDisplayColumns( getUpdatedColummnsConfig( columnsConfig ) )
    }, [formState])

    const [ formErrors, setFormErrors ] = React.useState( errors )
    const [ formLoader, setFormLoader ] = React.useState( loader )

    const setSuccessResponse = ( response ) => {
        onSuccessCallback( response )
    }

    

    const onFormSubmit = ( event) => {
        event.preventDefault()

        //method to sanitize form input
        const formSanitizedData = ( ) => {
            let formSchema = {}
            //set only properties with display true
            formDislayColumns.filter( item => !item.viewOnly ).map( item => { 
                formSchema[item.column] = formState[ item.column ]
                return item
            })
            return formSchema
        }
        
        const sanitizedData = formSanitizedData()
        //console.log( sanitizedData , " sanitizedData ")
        //call form submit if method is provided
        if( handleFormSubmit ) {
            handleFormSubmit( sanitizedData )
            return;
        }

        //call API if url is provided
        if( apiUrl ) {
            setFormLoader( true )
            callApiFromUrl( { apiUrl: apiUrl, params: sanitizedData, setErrors : setFormErrors, setSuccessResponse : setSuccessResponse } )
        }
    }
    
    React.useEffect( () => {
        setFormLoader( false )
    }, [ formErrors ] )

    const makeTwoColumns = formDislayColumns.length > 5 
    const listParition = makeTwoColumns ? formDislayColumns.length / 2 : formDislayColumns.length

    return <form>
        { successMsg && <div className="alert alert-default-success show" role="alert">
            {successMsg}
        </div> }
        <div className="row">
            <div className={ makeTwoColumns ? "col-md-6" : "col-md-8" }>
                {
                    formDislayColumns.slice(0, listParition ).map( (item) =>{
                        return <RenderFormInput key={"formInput_"+item.column} item={item} formState={formState} errors={ formErrors } formEditing={formEditing} setFormState={ setFormState } />
                    })
                }
            </div>
            { makeTwoColumns && <div className="col-md-6">
                {
                    formDislayColumns.slice( listParition , formDislayColumns.length).map( (item, index) =>{
                        return <RenderFormInput key={"formInput_"+item.column} item={item} formState={formState} errors={ formErrors } formEditing={formEditing} setFormState={ setFormState } />
                    })
                }
            </div> }
        </div>

        { formEditing && <button type="submit" className={"btn btn-primary "+ ( formLoader ? "disabled" : "")} onClick={ onFormSubmit }>
        { formLoader && <div className="spinner-border spinner-border-sm mr-1" role="status">
            <span className="sr-only">Loading...</span>
        </div> }
        Submit</button> }

    </form>
}

FormTemplate.defaultProps = {
    successMsg: "",
    formEditing: true,
    errors: {},
    loader: false,
    handleFormSubmit: undefined,
    apiUrl: "",
    onSuccessCallback: undefined
}

export default FormTemplate