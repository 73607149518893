import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )

export function Report( {pageConfig}) {

    const labels = pageConfig.dataSet.labels//.map( item => item.label);
    
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: pageConfig.pageTitle,
          },
        },
    };

    const data = {
        labels,
        datasets: [
          {
            label: pageConfig.pageTitle,
            data: pageConfig.dataSet.records,
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
        ],
    };

    return <Line options={options} data={data} />
}