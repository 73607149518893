import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { callVerifyResetTokenApi } from "../../services/ApiService";
import { callResetAccountPasswordApi } from "../../services/ApiService";
import { Link } from "react-router-dom";

export default function ResetPassword(){

    const { resetToken } = useParams()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errors, setErrors] = useState({})
    const [successAlert, setSuccessAlert ] = useState("")
    const [ loader, setLoader] = useState( false )
    
    console.log( resetToken, "resetToken")

    useEffect( async () =>{
        const serviceArgs = {
            resetToken: resetToken
        }
        
        let errorMsgs = {}
        /** We're not handling success reponse on of this API */
        const response = await callVerifyResetTokenApi( serviceArgs )

        if( ("errors" in response) && Object.keys(response.errors).length ) {
            //error object in response will be a struct of array of error msgs
            for( const errorObj in response.errors ) {
                errorMsgs[ errorObj ] = response.errors[errorObj][0]
            }
        }

        if( Object.keys(errorMsgs).length > 0) {
            setErrors( errorMsgs )
        }

    }, [ resetToken ] )


    //method to handle form submission
    const handleResetPasswordSubmit = async ( event ) => {
        event.preventDefault()

        if( loader )
            return;

        setLoader(true)

        let errorMsgs = {}
        if( confirmPassword === "")
            errorMsgs.confirmPassword = "This field is required."
        else if( confirmPassword !== password) 
            errorMsgs.confirmPassword = "Passwords must match."
        if( password === "")
            errorMsgs.password = "This field is required."
        
        if( Object.keys(errorMsgs).length === 0) {
            const serviceArgs = {
                resetToken: resetToken,
                password: password,
                confirmPassword: confirmPassword
            }

            const response = await callResetAccountPasswordApi( serviceArgs )

            if( ("errors" in response) && Object.keys(response.errors).length ) {
                //error object in response will be a struct of array of error msgs
                for( const errorObj in response.errors ) {
                    errorMsgs[ errorObj ] = response.errors[errorObj][0]
                }
            }

            if( ("message" in response ) && response.message.length > 0 ) {
                setSuccessAlert( response.message )
            }
        }

        setErrors( errorMsgs )
        setLoader( false )
        //call API to reset Password
    }

    return (
            <div className="card-body">
                <p className="login-box-msg">Reset Your Password</p>

                { successAlert.length > 0 && <div class="alert alert-default-success" role="alert">
                    { successAlert }
                </div> }

                { ( "tokenExpiry" in errors ) && <div class="alert alert-default-danger" role="alert">
                    { errors.tokenExpiry }
                </div> }

                { !( "tokenExpiry" in errors ) && <form onSubmit={handleResetPasswordSubmit}>
                        <div className="input-group mb-3">
                            <input type="password" className={ "form-control " + (  "password" in errors ? "is-invalid": "") } placeholder="Password" value={password} onChange={ (event) => setPassword(event.target.value) } /> 
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                            { "password" in errors && <span className="error invalid-feedback"> {errors.password}</span> }
                        </div>
                        <div className="input-group mb-3">
                            <input type="password" className={ "form-control " + (  "confirmPassword" in errors ? "is-invalid": "") } placeholder="Confirm Password" value={confirmPassword} onChange={ (event) => setConfirmPassword(event.target.value) } /> 
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-lock"></span>
                                </div>
                            </div>
                            { "confirmPassword" in errors && <span className="error invalid-feedback"> {errors.confirmPassword}</span> }
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <button type="submit" className={"btn btn-primary btn-block " + ( loader ? "disabled" : "")}>
                                    { loader && <div className="spinner-border spinner-border-sm mr-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> }
                                    Reset</button>
                            </div>
                        </div>
                    </form>
                }

                <p className="mb-1 pt-2">
                    <Link to="/login"> Go to login </Link>
                </p>
            </div>
    )
}