export default function NotFoundPage(){

    return (
        <div className="content-wrapper">
            <section className="content" style={{ padding: "15px .5rem"}}>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">404 Page Not Found</h3>
                                </div>

                                <div className="card-body">
                                    <div className="alert alert-default-danger" role="alert">
                                        Invalid Route, 404.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}