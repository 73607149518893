import { useState } from "react"

export default function TablePagination ( { nextLabel = " Next >> ", prevLabel = "<< Prev", paginator = {}, handlePageChange } ) {

    //const [ disablePreLink, setDisablePreLink ] = useState(true)
    //const [ disableNextLink, setDisableNextLink ] = useState( true )
    const disablePreLink = !("prePageCursor" in paginator) || !paginator.prePageCursor
    const disableNextLink = !("nextPageCursor" in paginator) || !paginator.nextPageCursor
    
    const [ currentPage, setCurrentPage ] = useState( 1 )

    //const totalPages = Math.floor(totalCount / itemPerPage);

    //const defaultPageCountLimit;
    const handlePrevClick = (e) => {
        e.preventDefault()
        if( !disablePreLink) {
            setCurrentPage( currentPage - 1 > 1 ? currentPage - 1 : 1  )
            handlePageChange( paginator.prePageCursor )
        }
        
    }

    const handleNextClick = (e) => {
        e.preventDefault()
        if( !disableNextLink ) {
            setCurrentPage( currentPage + 1 )
            handlePageChange( paginator.nextPageCursor )
        }
    }

    return <ul className="pagination pagination-sm m-0 float-right">
            <li className={ "page-item " + (disablePreLink ? "disabled" : "") } ><a className="page-link" href="/" onClick={ handlePrevClick } >{ prevLabel }</a></li>
            {/* <li className="page-item disabled"><a className="page-link disabled" href="/" onClick={ (e) => e.preventDefault() }>{ currentPage }</a></li> */}
            <li className={ "page-item " + (disableNextLink ? "disabled" : "") }><a className="page-link" href="/" onClick={ handleNextClick } >{ nextLabel }</a></li>
    </ul>
}