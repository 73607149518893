
import FormTemplate from "../../forms/common/FormTemplate"

export default function DefinedFiltersList( {displayProperties, appliedFilters, setAppliedFilters, setModalStateProp } ) {

    //console.log( appliedFilters, " appliedFilters ")
    const definedFilterColumns = displayProperties.filter( item => item.definedFilter === true ).map( ( item ) => {
        const existingFilter = appliedFilters.find( filterItem => !item.disabled && item.display && item.column === filterItem.column )
        if( existingFilter ) {
            return {
                ...item,
                "value": existingFilter["filterValue"] + ( existingFilter["filterEndValue"] ? " : " + existingFilter["filterEndValue"] : "" )
            }
        }

        return item
    })

    let LappliedFilters = appliedFilters

    const handleFormSubmit = ( response ) =>{
        const newFilters = [];
        //console.log( LappliedFilters, " LappliedFilters before ")
        for( const key in response ) {
            if( response[key] !== "") {
                
                let filterValue = response[key]
                let filterEndValue = null
                let filterOperator = response[key].indexOf(",") !== -1 ? "inList" : "="
                //check for date range
                if( response[key].indexOf(" : ") !== -1 ) {
                    const [ startValue , endDate ] = response[key].split(" : ")
                    filterValue = startValue
                    filterEndValue = endDate
                    filterOperator = "between"
                }

                newFilters.push({
                    "column": key,
                    "operator": filterOperator,
                    "filterValue": filterValue,
                    "filterEndValue" : filterEndValue
                })
            } else {
                const existingFilter = LappliedFilters.find( filterItem => !filterItem.disabled && filterItem.display && key === filterItem.column )
                if( existingFilter ) {
                    LappliedFilters = LappliedFilters.filter( (filter) => filter.column !== key )
                }
            }
        }
        
        setAppliedFilters(( appliedFilters ) => {
            //const cleanFilters = newFilters.filter( filterItem => )
            return [
            ...LappliedFilters,
            ...newFilters]
        })

        //console.log( LappliedFilters, " LappliedFilters after ")

        setModalStateProp( false )
    }

    // console.log( definedFilterColumns, " definedFilterColumns ::: listActions ")

    return <FormTemplate columnsConfig={ definedFilterColumns } handleFormSubmit={ handleFormSubmit } />
}