import { useEffect, useState } from "react"
import { useBatchSelection } from "./BatchSelectionContext"

export function BatchSelection({ selectedValue = "" }){

    const { isSelected, headerPreSelected, handleSelectRecord, bulkSelectedRecords } = useBatchSelection()
    const [ selected, setSelected ] = useState( selectedValue === "" ? headerPreSelected : isSelected( selectedValue ) )

    useEffect( () => {
        setSelected( selectedValue === "" ? headerPreSelected : isSelected( selectedValue ) )
    }, [ bulkSelectedRecords ])

    return <div className="form-group">
    <div className="form-check">
        <input className="form-check-input" type="checkbox" checked={ selected } onChange={ () => {
            setSelected( !selected )
            handleSelectRecord( !selected, selectedValue )
        }} />
    </div>
</div>
}