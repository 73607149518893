import { useRef, useEffect, useState } from "react"

const useHandleClickOutside = () => {
    
    const [ showList, setShowList ] = useState(false)
    const buttonRef = useRef(null)
    
    useEffect(() => {
        const handleClickOutside = (event ) => {
          if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            setShowList(false);
          }
        };
    
        // Add the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Remove the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return { buttonRef, showList, setShowList }
}

export default useHandleClickOutside;