import { useState } from 'react';

export default function useFilterOperators() {
    const getFilterOperatorList = () => {
        const filterString = localStorage.getItem('appFilterOperator')
        return JSON.parse( filterString )
    };

    const [filterOperatorList, setFilterOperatorList] = useState(getFilterOperatorList())

    const saveOperatorList = filterOperatorList => {
        if( typeof filterOperatorList !== "object") {
            alert("appFilterOperator is expecting an Object.")
            return
        }
        localStorage.setItem('appFilterOperator', JSON.stringify( filterOperatorList) )
        setFilterOperatorList(filterOperatorList)
    }

    return {
        filterOperatorList,
        saveOperatorList
    }
}