import { Link } from "react-router-dom"
import React from 'react'
import ConfirmModal from "../../../common/ConfirmModal"
import FormTemplate from "../../forms/common/FormTemplate"
import { ToastContainer, toast } from 'react-toastify';

export function ListPageTableSection ( {refreshList, entity, listActionBar, showCreateButton=true, children, createFormConfig = {} } ) {
    const [showConfirmModal, setShowConfirmModal] = React.useState( false )

    const onSuccessCallback = ( response ) => {
        toast.success( response.message )
        setShowConfirmModal( false )
        refreshList()
    }

    //console.log( createFormConfig, " createFormConfig ")

    const modalBodyComponent = showCreateButton ? <FormTemplate columnsConfig={createFormConfig['pageConfig']['columnsConfig']} apiUrl={ createFormConfig['pageConfig']['apiUrl'] } onSuccessCallback={ onSuccessCallback } /> : null

    return <div className="row">
            <ToastContainer />
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                      { showCreateButton && <h3 className="card-title">
                            <Link to={"/"+entity+"/view"} onClick={(e) =>{
                                setShowConfirmModal( true )
                                e.preventDefault()
                            }}> <i className="fa fa-plus"></i> </Link>
                        </h3> }
                        { listActionBar }
                    </div>
  
                    <div className="card-body">
                    { children }
                    </div>
                </div>
            </div>
            { showCreateButton && showConfirmModal && <ConfirmModal modalTitle={ createFormConfig['pageTitle'] } modalStateProp={ showConfirmModal } setModalStateProp={ setShowConfirmModal } modalSize={ "large" } 
                modalBodyComponent={ modalBodyComponent } hideFooter = { true }
            />}
        </div>
        
  }