import { useState } from 'react';

export default function useAccount() {

    const getAccount = () => {
        const accountString = localStorage.getItem('appAccount')
        return JSON.parse( accountString )
    };

    const [account, setAccount] = useState(getAccount())

    const saveAccount = accountObj => {
        if( typeof accountObj !== "object") {
            alert("useAccount is expecting an Object.")
            return
        }
        localStorage.setItem('appAccount', JSON.stringify(accountObj) )
        setAccount(accountObj)
    }

    return {
        account,
        saveAccount
    }
}