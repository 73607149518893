import { useState } from "react";
import PropTypes from 'prop-types';
import { callLoginApi } from "../../services/ApiService";
import DemoCreds from "./DemoCreds";
import { Link } from "react-router-dom";
import useApiError from "../../hooks/useApiError";
import useMenu from "../../hooks/useMenu";
import useAccount from "../../hooks/useAccount";
import useFilterOperators from "../../hooks/useFilterOperators";
import { SHOW_DEMO_CREDS } from "../../constants/app";

export default function Login({setToken}){

    const [emailAddress, setEmailAddress] = useState("")
    const [password, setPassword] = useState("")
    const [errors, setErrors] = useApiError()
    const [ loader, setLoader] = useState( false )
    const { saveMenuList } = useMenu()
    const { saveAccount } = useAccount()
    const { saveOperatorList } = useFilterOperators()

    //method to handle login form submission
    const handleLoginSubmit = async event => {
        event.preventDefault()

        if( loader )
            return;

        setLoader(true)

        let errorMsgs = {}
        if( emailAddress === "")
            errorMsgs.emailAddress = "This field is required."

        if( password === "")
            errorMsgs.password = "This field is required."
        
        if( Object.keys(errorMsgs).length === 0) {
            //call API to validate login credentials
            const loginArgs = {
                emailAddress: emailAddress,
                password: password
            }
            const response = await callLoginApi( loginArgs )

            if( ("errors" in response) && Object.keys(response.errors).length ) {
                //error object in response will be a struct of array of error msgs
                for( const errorObj in response.errors ) {
                    errorMsgs[ errorObj ] = response.errors[errorObj][0]
                }

                setErrors( errorMsgs )
                setLoader( false )
            }

            if( "menu" in response?.data ) {
                saveMenuList( response.data.menu )
            }

            if( "account" in response?.data ) {
                saveAccount( response.data.account )
            }

            if( "filterOpeartors" in response?.data ) {
                saveOperatorList( response.data.filterOpeartors )
            }

            if( "token" in response?.data ) {
                setToken( response.data.token )
            }
            
        } else {
            setErrors( errorMsgs )
            setLoader( false )
        }
    }

    return (
            <div className="card-body">
                <p className="login-box-msg">Sign in to start your session</p>

                <span className="error invalid-feedback"> Sample error </span>

                <form onSubmit={handleLoginSubmit}>
                    <div className="input-group mb-3">
                        <input type="text" className={ "form-control " + (  "emailAddress" in errors ? "is-invalid": "") } placeholder="Email" value={ emailAddress
                        } onChange={ (event) => setEmailAddress(event.target.value) } />
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-envelope"></span>
                            </div>
                        </div>
                        { "emailAddress" in errors && <span className="error invalid-feedback"> {errors.emailAddress}</span> }
                    </div>
                    <div className="input-group mb-3">
                        <input type="password" className={ "form-control " + (  "password" in errors ? "is-invalid": "") } placeholder="Password" value={password} onChange={ (event) => setPassword(event.target.value) } /> 
                        <div className="input-group-append">
                            <div className="input-group-text">
                                <span className="fas fa-lock"></span>
                            </div>
                        </div>
                        { "password" in errors && <span className="error invalid-feedback"> {errors.password}</span> }
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <button type="submit" className={"btn btn-primary btn-block " + ( loader ? "disabled" : "")}>
                                { loader && <div className="spinner-border spinner-border-sm mr-1" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> }
                                Sign In</button>
                        </div>
                    </div>
                </form>

                <p className="mb-1 pt-2">
                    <Link to="/forgotPassword"> Forgot password ? </Link>
                </p>
                
                { SHOW_DEMO_CREDS && <DemoCreds setEmailAddress={setEmailAddress} setPassword={setPassword} /> }
            </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}