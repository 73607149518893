import { Link } from "react-router-dom";
import { useState } from "react";

export default function FormAccordianTemplate({ pageTitle, hideShowAction=false, expandDefault = false, cardTools, ListTemplate, FormTemplate }){

    const [ isExpand, setIsExpand ] = useState( expandDefault )
    
    const expandIcon = !hideShowAction && (!isExpand ? <i className="fa fa-arrow-down"></i> : <i className="fa fa-arrow-up"></i>)

    return <div className="row">
        <div className="col-md-12">
            <div className="card card-default">
                <div className="card-header">
                    <h3 className="card-title"> <Link onClick={(e)=> { e.preventDefault(); 
                        if( !hideShowAction) setIsExpand( !isExpand )  } } to="" title="Toggle Display"> { pageTitle } { expandIcon } </Link> </h3>
                    { typeof cardTools !== undefined && cardTools }
                </div>
                { isExpand && <div className="card-body">
                    { typeof ListTemplate !== undefined && ListTemplate }
                    { typeof FormTemplate !== undefined && FormTemplate }
                </div> }
            </div>
        </div>
    </div>
}