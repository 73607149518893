export default function PageHeader( { pageTitle = "" } ){
    return (
    <section className="content-header">
      { pageTitle !== "" && <>
      <div className="container-fluid">
        <div className="row mb-2">
          
          <div className="col-sm-6">
            <h2 className="m-0"> <small>{ pageTitle }</small> </h2>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item active"> { pageTitle }</li>
            </ol>
          </div>
          
        </div>
      </div>
      </>}
    </section>
    )
}