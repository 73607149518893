import { Link, useNavigate } from "react-router-dom";
import { FaEdit, FaTrash, FaBackspace, FaTimesCircle } from 'react-icons/fa'
import ConfirmModal from "../../../common/ConfirmModal";
import { useState } from "react";
import { callApiFromUrl } from "../../../services/ApiService";
import { callGetApiFromUrl } from "../../../services/ApiService";
import { ToastContainer, toast } from 'react-toastify';
import ProcessActions from "../FormActions/ProcessActions";

export default function CardTools({ setFormEditing, formEditing, pageActions, cancelEditAction }) {

    const navigate = useNavigate()

    const navigateToList = () => {
        navigate( pageActions['backLink']['apiUrl'] )   
    }

    const [showDeleteModal, setShowDeleteModal] = useState( false )

    return <><div className="card-tools">
        <ToastContainer />
        <ul className="pagination pagination-sm float-right">
            {  ("backLink" in pageActions ) && <li className="page-item">
                <button className="page-link" onClick={ (e) => {
                    navigateToList()
                 } } title="Back">
                    <FaBackspace /> Back 
                </button>
            </li> }
            { ("editLink" in pageActions ) && !formEditing && <li className="page-item" style={{ "padding" : 0 }}>
                <button className="page-link" onClick={ (e) => setFormEditing( true ) } title="Edit"> <FaEdit /> Edit </button>
            </li> }
            { formEditing  && <li className="page-item" style={{ "padding" : 0 }}>
                <button className="page-link" onClick={ (e) => cancelEditAction() } title="Cancel Editing"> <FaTimesCircle /> Cancel </button>
            </li> }
            { ("deleteLink" in pageActions ) && ("backLink" in pageActions ) && <li className="page-item" style={{ "padding" : 0 }}>
                <button className="page-link" onClick={ (e) => { setShowDeleteModal(true) } } title="Delete"> <FaTrash /> Delete </button>
            </li> }

            { ("processActions" in pageActions)  && <ProcessActions actionsList = { pageActions['processActions'] } /> }
        </ul>
    </div>
    
    { setShowDeleteModal && <ConfirmModal modalSize="medium" actionApiUrl={ pageActions?.deleteLink?.apiUrl || "" } modalStateProp={ showDeleteModal } modalBodyText = "Are you sure you want to delete this record ?" setModalStateProp={ setShowDeleteModal } modalSuccessCallback={ ()=>{ navigateToList() }} /> }
    </>
}