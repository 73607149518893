import { useState } from "react";
import { callForgotPasswordApi } from "../../services/ApiService";
import { Link } from "react-router-dom";

export default function ForgotPassword(){

    const [emailAddress, setEmailAddress] = useState("")
    const [errors, setErrors] = useState({})
    const [successAlert, setSuccessAlert ] = useState("")
    const [ loader, setLoader] = useState( false )
    
    //method to handle form submission
    const handleForgotPasswordSubmit = async ( event ) => {
        event.preventDefault()

        if( loader )
            return;

        setLoader(true)

        let errorMsgs = {}
        if( emailAddress === "")
            errorMsgs.emailAddress = "This field is required."
        
        if( Object.keys(errorMsgs).length === 0) {
            //call API to validate login credentials
            const serviceArgs = {
                emailAddress: emailAddress
            }
            
            const response = await callForgotPasswordApi( serviceArgs )

            if( ("errors" in response) && Object.keys(response.errors).length ) {
                //error object in response will be a struct of array of error msgs
                for( const errorObj in response.errors ) {
                    errorMsgs[ errorObj ] = response.errors[errorObj][0]
                }
            }
            
            if( ("message" in response ) && response.message.length > 0 ) {
                setSuccessAlert( response.message )
            }
        }
        
        setLoader( false )
        setErrors( errorMsgs )

        //handle success 
    }

    return (
            <>
                <div className="card-body">
                    <p className="login-box-msg">Forgot Password ?</p>

                    { successAlert.length > 0 && <div class="alert alert-default-success" role="alert">
                        { successAlert }
                    </div> }

                    <form onSubmit={handleForgotPasswordSubmit}>
                        <div className="input-group mb-3">
                            <input type="text" className={ "form-control " + (  "emailAddress" in errors ? "is-invalid": "") } placeholder="Email" value={ emailAddress
                            } onChange={ (event) => setEmailAddress(event.target.value) } />
                            <div className="input-group-append">
                                <div className="input-group-text">
                                    <span className="fas fa-envelope"></span>
                                </div>
                            </div>
                            { "emailAddress" in errors && <span className="error invalid-feedback"> {errors.emailAddress}</span> }
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <button type="submit" className={"btn btn-primary btn-block " + ( loader ? "disabled" : "")}>
                                    { loader && <div className="spinner-border spinner-border-sm mr-1" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> }
                                    Submit</button>
                            </div>
                        </div>
                    </form>

                    <p className="mb-1 pt-2">
                        <Link to="/login"> Go to login </Link>
                    </p>
                </div>
            </>
    )
}