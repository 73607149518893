/***
 * Modify Display List
 * Bulk Delete
 * Export To Excel
 * Add Filters
 * Save / Load your Collection
***/
import React from 'react'
import { FaList, FaFilter, FaFileExport, FaSave, FaSpinner, FaFileImport } from 'react-icons/fa'
import { DisplayColumnsList, FilterColumnsList, ImportData, DefinedFiltersList } from './ListActions'
import ConfirmModal from '../../common/ConfirmModal'
import makeUniqueId from '../../hooks/useMakeUniqueId'
import ExportColumnsList from './ListActions/ExportColumnsList'
import ProcessActions from '../forms/FormActions/ProcessActions'
import { useBatchSelection } from './common/BatchSelection'

function ListActionBar( { listActions, displayProperties, handleDisplayPropertiesChange, appliedFilters, setAppliedFilters, refreshList, recordsPerPage, entity, sortOrder, sortColumn, pageGroupBy } ) {
    const [ modelActionComponent , setModelActionComponent ] = React.useState()
    const [ modelStateProp , setModelStateProp ] = React.useState()
    const { bulkSelectedRecords, setBulkSelectedRecords } = useBatchSelection()

    const [ modalSize, setModalSize ] = React.useState("medium")

    const handleDisplayColumnsList = () => {
        setModelStateProp( true )
        setModalSize("medium")
        setModelActionComponent(  <DisplayColumnsList key={ makeUniqueId("displayColumns_") } displayProperties={ displayProperties } handleDisplayPropertiesChange = { handleDisplayPropertiesChange } setModalStateProp = {setModelStateProp} recordsPerPage={ recordsPerPage } /> )
    }

    const handleShowFilterColumnsList = () => {
        setModelStateProp( true )
        setModalSize("medium")
        setModelActionComponent( <FilterColumnsList  key={ makeUniqueId("filterColumns_") } displayProperties={ displayProperties } appliedFilters={appliedFilters} setAppliedFilters = {setAppliedFilters} setModalStateProp = {setModelStateProp} /> )
    }

    const handleShowImportData = () => {
        setModelStateProp( true )
        setModalSize("large")
        setModelActionComponent( <ImportData pageConfig={ listActions?.import } setModalStateProp = {setModelStateProp} successCallback={ () => refreshList() } key={ makeUniqueId("importData_") } /> )
    }

    const handleShowExportData = () => {
        setModelStateProp( true )
        setModalSize("medium")
        setModelActionComponent(  <ExportColumnsList key={ makeUniqueId("displayColumns_") } displayProperties={ displayProperties } appliedFilters={appliedFilters} entity={ entity } sortColumn={ sortColumn } sortOrder={ sortOrder } pageGroupBy={pageGroupBy}/> )
    }

    const handleShowDefinedFiltersList = () => {
        setModelStateProp( true )
        setModalSize("medium")
        setModelActionComponent( <DefinedFiltersList  key={ makeUniqueId("definedFilterColumns_") } displayProperties={ displayProperties } appliedFilters={appliedFilters} setAppliedFilters = {setAppliedFilters} setModalStateProp = {setModelStateProp} /> )
    }
    
    return (
        <>
        <div className="card-tools">
            <ul className="pagination pagination-sm float-right">
                { "display" in listActions && <li className="page-item" >
                    <button className="page-link" onClick={ handleDisplayColumnsList } title="Display Columns"> <FaList /> </button>
                </li> }
                
                {/* { "filters" in listActions && <li className="page-item" >
                    <button className="page-link" onClick={ handleShowFilterColumnsList } title="Filter List"> <FaFilter /> </button>
                </li> } */}

                { "import" in listActions  && <li className="page-item" >
                    <button className="page-link" onClick={ handleShowImportData } title="Import">
                        <FaFileImport />
                    </button>
                </li> }

                { ("export" in listActions ) && <li className="page-item" >
                    <button className="page-link" onClick={ handleShowExportData } title="Export Records">
                        <FaFileExport />
                    </button>
                </li> }
                
                { /* 
                DefinedFiltersList
                <li className="page-item" style={{ "padding" : 0 }}>
                    <button className="page-link" title="Save / Load Collection"> <FaSave /> </button>
                </li> */}

                { !( "hideRefresh" in listActions) && <li className="page-item" >
                    <button className="page-link" onClick={ (e) => refreshList() } title="Refresh"> <FaSpinner /> </button>
                </li> }

                { ("selectBulkActions" in listActions && listActions['selectBulkActions'].length > 0)  && <ProcessActions actionsList = { listActions['selectBulkActions'] } bulkSelectedIds = { bulkSelectedRecords } validateBulkIds={ true } onSuccessCallback={ () => {
                    setBulkSelectedRecords([])
                    refreshList()
                 } } /> }

                 { ("definedFilters" in listActions && listActions["definedFilters"] ) && <li className="page-item" >
                    <button className="page-link" onClick={ handleShowDefinedFiltersList } title="Defined Filters"> <FaFilter /> </button>
                </li> }
                
            </ul>
        </div>

        { modelStateProp && <ConfirmModal modalSize={ modalSize } modalTitle="" modalBodyComponent={ modelActionComponent } modalStateProp={ modelStateProp } setModalStateProp={ setModelStateProp } hideFooter={ true } /> }
        </>
    )
}

export default ListActionBar