import React, { createContext, useContext, useState } from 'react';

const BatchSelectionContext = createContext();

export function BatchSelectionProvider({ children, pageRecords = [], initialSelectedRecords = [], propertyName }) {

    const [ bulkSelectedRecords, setBulkSelectedRecords ] = useState( initialSelectedRecords )
  
    const handleSelectRecord = ( selected = true, value = "" ) => {
      const isHeaderAction = value === "";

      if( selected ) {
          if( isHeaderAction ) {
              pageRecords.map( ( item ) => setBulkSelectedRecords(( prevItems ) => prevItems.includes( item[ propertyName ] ) ? prevItems : [...prevItems, item[propertyName] ] ) )
          } else if( !bulkSelectedRecords.includes( value ) ) {
              setBulkSelectedRecords([...bulkSelectedRecords, value ])
          }
      } else {
          isHeaderAction ? 
              pageRecords.map( (item) => setBulkSelectedRecords(( prevItems ) => [...prevItems ].filter( (i) => i !== item[propertyName] )) ) 
              : setBulkSelectedRecords(bulkSelectedRecords.filter( (i) => i !== value ) )
      }
      
    }
  
    // Function to check if a record is selected
    const headerPreSelected = pageRecords.length > 0 && pageRecords.map( item => item[ propertyName ] ).every( value => bulkSelectedRecords.includes( value ))

    const isSelected = ( value ) => {
      return bulkSelectedRecords.includes( value )
    }
  
    return (
      <BatchSelectionContext.Provider value={{ bulkSelectedRecords, setBulkSelectedRecords, handleSelectRecord, headerPreSelected, isSelected }}>
        {children}
      </BatchSelectionContext.Provider>
    );
}
  
  // Custom hook to access the selection context
export function useBatchSelection() {
  const context = useContext(BatchSelectionContext);
  if (!context) {
    throw new Error('useBatchSelection must be used within a BatchSelectionProvider');
  }
  return context;
}