import * as React from "react";
import useToken from "../../hooks/useToken";
import { callMeApi } from "../../services/ApiService";
import { useNavigate } from "react-router-dom";
import useAccount from "../../hooks/useAccount";

const useHeaderHook = () => {
    const [showSideBar, setShowSideBar] = React.useState(false)
    const { saveToken } = useToken()
    const { account } = useAccount()
    const [ userName, setUserName ] = React.useState( account ? account.firstName+" "+account.lastName : "" )
    const [ userID, ] = React.useState( account?.accountID )
    const navigate = useNavigate()

    //toggle sidebar
    const toggleSideBar = (e) => {
        e.preventDefault()
        setShowSideBar( !showSideBar )
    }

    React.useEffect( ()=>{
        //call api to get user information
        callMeApi().then(response => {
            //calling call me API
            if(  !("errors" in response ) ) {
                setUserName( response.firstName + " " + response.lastName)
            } else {
                logout()
            }
        })
    },[])

    //logout user
    const logout =() =>{
        saveToken("")
        //navigate("/")
        window.location.href = "/";
    }

    return {
        showSideBar,
        logout,
        toggleSideBar,
        userName,
        userID
    }
}

export default useHeaderHook;