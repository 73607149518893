import { ListTemplate } from "./list/ListTemplate"
import PageHeader from "./common/PageHeader"
import DefaultPageTemplate from "../common/pages/list/DefaultPageTemplate"
//import { useEffect, useState } from "react"
import React from 'react'
import { callGetPageConfigApi } from "../services/ApiService"
import FormAccordianTemplate from "./forms/common/FormAccordianTemplate"
import { Form } from "./forms/Form"
import makeUniqueId from "../hooks/useMakeUniqueId"
import { Report } from "./report/Report"
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

function PageLayout({ pageTitle = "", children }) {
    return <div className="content-wrapper"> 
            <PageHeader pageTitle={ pageTitle } />
            <section className="content">
                <div className="container-fluid">
                    { children }
                </div>
            </section>
        </div>
}

export default function PageTemplate({ entity, entityID, action }) {
    
    const [errors, setErrors] = React.useState({})
    //get entity from params
    
    const [ pageAccordians, setPageAccordians ] = React.useState([])
    const [ pageTitle, setPageTitle ] = React.useState("")
    const [ pageTabsStyle, setPageTabsStyle ] = React.useState("accordion")
    const [isWholePageLoading, setIsWholePageLoading] = React.useState(false)

    const setSuccessResponse = ( response ) => {
        if( response.data?.pageTitle )
            setPageTitle( response.data.pageTitle )
        if( response.data?.pageTabsStyle === "single" )
            setPageTabsStyle( "single" )
        
        setPageAccordians( response.data.pageTabs )
    }

    React.useEffect( async () =>{
        setIsWholePageLoading( true )
        //call get table API or get form api
        await callGetPageConfigApi( {entityName: entity, pageAction : action, entityID : entityID, setErrors : setErrors, setSuccessResponse : setSuccessResponse } )
        setIsWholePageLoading( false )

    },[entity, action, entityID] )

    const renderAccordion = ( page, index ) => {   
        return <React.Fragment>{ page.pageType === "form" && <Form entityID={page.entityID} pageTitle={ page.pageTitle} pageConfig={ page.pageConfig } /> }
            { page.pageType === "list" && <FormAccordianTemplate
                    pageTitle={ page.pageTitle }
                    ListTemplate={ page.pageType==="list" && <ListTemplate entity={ page.entity } 
                            pageTitle={ page.pageTitle }
                            pageConfig={ page.pageConfig }
                            />}
                    expandDefault={ pageAccordians.length === 1 || index === 0 || pageTabsStyle ==="single" }
                    hideShowAction={ pageAccordians.length === 1 }
                    />
            }
            { page.pageType === "report" && <FormAccordianTemplate
                    pageTitle={ page.pageTitle }
                    FormTemplate={ <Report pageConfig = { page.pageConfig} pageTitle = { page.pageTitle } /> }
                    expandDefault={ pageAccordians.length === 1 || index === 0 || pageTabsStyle ==="single" }
                    hideShowAction={ pageAccordians.length === 1 }
                    /> }
        </React.Fragment>
    }

    const renderAccordians = () => {
        return <React.Fragment>{ pageAccordians.map( ( page, index ) => {
                
            return <React.Fragment key={ makeUniqueId("formpage_" + entity + index) }>
                    { pageTabsStyle ==="single" && <div className="col-6">
                        { renderAccordion( page, index ) }
                    </div> }
                    { pageTabsStyle === "accordion" && renderAccordion( page, index )}
            </React.Fragment>
        })}
        </React.Fragment>
    }

    return <>
        { isWholePageLoading && <DefaultPageTemplate loader = { isWholePageLoading } /> }
        { ( "modelName" in errors ) && <DefaultPageTemplate pageTitle="Configuration Error" message={errors.modelName} alertBootstrapClass="danger" /> }

        { !isWholePageLoading && !errors.length && <PageLayout pageTitle={ pageTitle }>
            { pageTabsStyle === "single" && <div className="row" style={{ fontSize : "12px"}}>
                { renderAccordians() }
            </div> }
            { pageTabsStyle === "accordion" && renderAccordians() }
            </PageLayout> }
    </>
}