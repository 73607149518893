//import useApiError from "../hooks/useApiError";
import { apiUrl } from "../constants/app";
const baseUrl = apiUrl

export async function callLoginApi(credentials) {
    const response = await postRequest( "submitlogin", credentials )
    return response;
}

export async function callResetAccountPasswordApi( params ) {
  return await postRequest( "resetAccountPassword", params )
}

export async function callForgotPasswordApi( params ) {
    const response = await postRequest( "forgotPassword", params )
    return response;
}

function handleResponse( setErrors = Function(), response, setSuccessResponse = Function() ) {

  //handle errors
  if( ("errors" in response) && Object.keys(response.errors).length ) {
    let errorMsgs = {}
    //error object in response will be a struct of array of error msgs
    for( const errorObj in response.errors ) {
        errorMsgs[ errorObj ] = response.errors[errorObj][0]
    }

    if( Object.keys(errorMsgs).length > 0) {
        setErrors( errorMsgs )
    }
  } else if( ("data" in response ) && ( Object.keys(response.data).length > 0 || response.message !== "") ) {
    setSuccessResponse( response )
    setErrors({})
  }

}

export async function  callListModelApi( entityName, params, setErrors = Function() , setSuccessResponse = Function() ) {
  const response = await postRequest( entityName + "/list", params )
  handleResponse( setErrors, response, setSuccessResponse )

  return response;
}

export async function  callExportModelApi( entityName, params, setErrors = Function() , setSuccessResponse = Function() ) {
  const response = await postRequest( entityName + "/export", params )
  handleResponse( setErrors, response, setSuccessResponse )

  return response;
}

export async function callGetModelApi( entityName, entityID = "", setErrors = Function() , setSuccessResponse = Function() ) {
  const response = await getRequest( entityName + "/get" + (entityID ? "/"+entityID : "" ) )
  handleResponse( setErrors, response, setSuccessResponse )
}

export async function callGetPageConfigApi( {entityName, entityID = "", pageAction, setErrors = Function(), setSuccessResponse = Function() }) {

  const response = await getRequest(  entityName + "/" + pageAction + (entityID ? "/"+entityID : ""  ) + "/getPageConfig" )
  handleResponse( setErrors, response, setSuccessResponse )

  return response;
}

export async function callVerifyResetTokenApi( params ) {
  return await postRequest( "verifyResetToken", params )
}

export function callSaveModelApi( entityName, entityID = "", params ) {
  return postRequest( entityName + "/save" + (entityID ? "/"+entityID : "" ), params )
}

export async function callApiFromUrl( { apiUrl, params, setErrors = Function() , setSuccessResponse = Function() }) {
  const response = await postRequest( apiUrl, params )
  handleResponse( setErrors, response, setSuccessResponse )
  return response;
}

export async function callGetApiFromUrl( { apiUrl, setErrors = Function() , setSuccessResponse = Function() }) {
  const response = await getRequest( apiUrl )
  handleResponse( setErrors, response, setSuccessResponse )
  return response;
}

export function callDeleteModelApi( entityName, entityID) {
  return getRequest( entityName + "/delete/" + entityID )
}

export function callMeApi() {
  return getRequest("getAccount")
}

function makeApiUrl( url ) {
  if( baseUrl[ baseUrl.length - 1] == "/" &&  url[0] == "/" ) {
    url = url.substring(1)
  }

  return ( baseUrl + url )
}

function getRequest( url ) {
    const bearerToken = localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : "";
    return fetch( makeApiUrl( url ), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' :  bearerToken,
        }
      }).then(data => data.json())
}

function postRequest( url, body ){
  const bearerToken = localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : "";
  
    const response = fetch( makeApiUrl( url ), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization' :  bearerToken,
        },
        body: JSON.stringify( body )
      })
        .then(data => data.json())
  
  //const [error, setErrors] = useApiError()
  
  // if( ("errors" in response) && Object.keys(response.errors).length ) {
  //   let errorMsgs = {}
  //   //error object in response will be a struct of array of error msgs
  //   for( const errorObj in response.errors ) {
  //       errorMsgs[ errorObj ] = response.errors[errorObj][0]
  //   }

  //   setErrors( errorMsgs )
  // }

  return response;
}

/** TODO: add sanctum token handler complete code
 * 
 * https://laravel.com/docs/7.x/sanctum#spa-authentication
 */
// function getSanctumToken() {
//   return fetch( baseUrl + "")
// }

// function convertObjectToQueryString( params ) {
//     return Object.keys( params ).map( value => value+"="+params[value] ).join("&")
// }