import DraggableColumnsListUi from "./DraggableColumnsListUi"

export default function DisplayColumnsList( { displayProperties, handleDisplayPropertiesChange, setModalStateProp, recordsPerPage = 10 }) {

    const handleFormSubmit = (columnList, noOfRecords ) => {
        handleDisplayPropertiesChange( columnList, noOfRecords )
        setModalStateProp( false )
    }

    return <DraggableColumnsListUi modalTitle={"Display Columns List"} columnsConfig={ displayProperties } formSubmitCallback={handleFormSubmit} columnToggleAttribute={"display"} recordsPerPage={recordsPerPage} />
}