import * as React from "react";
import { Link } from "react-router-dom";
//import Logo from "../adminlte/logonew.png";
import { appName } from "../constants/app";
//import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useMenu from "../hooks/useMenu";

const MenuItem = ( props ) => {
    const hasChildren = props.children !== undefined && props.children.length > 0
    
    const [showChildren, setShowChildren] = React.useState(false)
    const [activeNavLinkClass, setActiveNavLinkClass ] = React.useState("")
    const parentMenuAnchorHref = hasChildren ? "/" : props.link
    
    React.useEffect(() => {
        //console.log( " parentMenuAnchorHref ", parentMenuAnchorHref, props.currentUrl === props.link, " checking for active link ")
        
        //if current URL matches exactly to menu link
        //and menu link is not blank
        //if it's a list page, display it active for all the view pages
        if( props.currentUrl === props.link 
            && ( parentMenuAnchorHref !== "/" && parentMenuAnchorHref !== "" ) 
            //|| (  props.link.includes("list") && props.currentUrl.includes("entityName/") )
        ) {
            setActiveNavLinkClass(" active ")
        } else {
            setActiveNavLinkClass("")
        }

    }, [props.currentUrl])

    const handleNavLinkClick = (e) => {
        if( (parentMenuAnchorHref === "/" && hasChildren) || parentMenuAnchorHref === "" ) 
            e.preventDefault()
        
        if( hasChildren ) {
            setShowChildren( !showChildren )
        }
    }

    return <li className={ "nav-item " + ( hasChildren ? "dropdown": "" ) + ( showChildren && hasChildren ? " menu-open" : "" ) } onClick={ (e) => {  } } > 
            <Link className={ props.childAnchorClass ? props.childAnchorClass : ("nav-link " + ( hasChildren && props.layoutType === "topbar" ? "dropdown-toggle": "" ) + activeNavLinkClass ) } 
                    to= { parentMenuAnchorHref } 
                    onClick= { handleNavLinkClick } >
                { props.icon !== "" && <i className={ "nav-icon " + props.icon }></i> }
                { props.layoutType === "topbar" && <span style={{paddingLeft: 5}}>{ props.title }</span> }
                { props.layoutType === "sidebar" && <p>{props.title}</p>}      
                { props.layoutType === "sidebar" && hasChildren && <i className="right fas fa-angle-left"></i>}
            </Link>
            { hasChildren &&  
                    props.children.map( ( item, index)=> 
                                        <ul key={"menu"+index} className={ (props.layoutType === "sidebar" ? "nav nav-treeview " : "dropdown-menu border-0 shadow " ) + (showChildren ? "show": "") }>
                                            <MenuItem { ...item } currentUrl={props.currentUrl} layoutType={props.layoutType} key={ index } />
                                        </ul> )  }

        
     </li>
}

const MenuBar = ( { layoutType } ) => {
    //sort based on sort order
    //prepare parent child structure
    const { menuList } = useMenu()
    
    //get current route to set active class on menu items
    const location = useLocation()
    const { pathname } = location
    return (
        <>
        {layoutType === "sidebar" && <aside className="main-sidebar sidebar-dark-primary elevation-4"> 
            <a href="/" onClick={ (e) => e.preventDefault() } className="brand-link">
                {/* <img src={Logo} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light"></span>
                */ }
                { appName }
            </a>

            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        { menuList && menuList.map( ( item, index)=> <MenuItem currentUrl = { pathname } { ...item } layoutType={layoutType} key={ "menu"+index } /> ) }
                    </ul>
                </nav>
            </div>
        </aside> }
        {layoutType === "topbar" && <nav className="main-header navbar navbar-expand-md navbar-light navbar-white pt-2">
            <div className="container">
                {/* TODO: make menu work in mobile view */}
                <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                    
                    <ul className="navbar-nav">
                        { menuList && menuList.map( ( item, index)=> <MenuItem { ...item } layoutType={layoutType} key={ "menu"+index } /> ) }
                    </ul>
                </div>
            
            </div>
        </nav> }
        </>
    )
}

/** TODO: define default proprety type */

export { MenuBar, MenuItem};