import { Routes, Route } from 'react-router-dom';
//import PageTemplate from '../pages/PageTemplate';
//import {Form} from '../pages/forms/Form';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import Dashboard from '../pages/Dashboard';
import DynamicPage from '../pages/DynamicPage';

export default function WebRoutes( ) {
    return (
        <Routes>
            <Route path="/" element={ <Dashboard /> } />
            <Route path="/:entity/:action" element={ <DynamicPage /> } />
            <Route path="/:entity/:action/:entityID" element={ <DynamicPage /> } />
            {/* <Route path="/:entity/view/:entityID" element={ <Form /> } />
            <Route path="/:entity/view" element={ <Form /> } /> */}
            <Route path="*" element={ <NotFoundPage /> } />
        </Routes>
    )
}