import { useState, useRef, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import ConfirmModal from "../../../common/ConfirmModal";
import FormTemplate from "../common/FormTemplate";
import { ListTemplate } from "../../list/ListTemplate";
import { ToastContainer, toast } from 'react-toastify';
import React from 'react'
import useHandleClickOutside from "../../../hooks/useHandleClickOutside";

//import { FaList, FaFilter, FaFileExport, FaSave, FaSpinner, FaFileImport } from 'react-icons/fa'

export default function ProcessActions( { actionsList, onSuccessCallback = "", bulkSelectedIds = [], validateBulkIds = false } ) {
    
    
    const { buttonRef, showList, setShowList }= useHandleClickOutside()

    const [showConfirmModal, setShowConfirmModal] = useState( false )

    const [ modalPageTitle, setModalPageTitle ] = useState("")
    const [ modalBodyComponent, setModalBodyComponent ] = useState("")
    const [ modalBodyText, setModalBodyText ] = useState("")
    const [ actionApiUrl , setActionApiUrl ] = useState("")
    const [ hideFooter, setHideFooter ] = useState( true )

    const expandIcon = !showList ? <i className="fa fa-arrow-down"></i> : <i className="fa fa-arrow-up"></i>
    
    const makeModalByJson = ( pageJson ) => {
        setShowConfirmModal( true )
        setModalPageTitle( pageJson['pageTitle'] )
        console.log( pageJson , "process JSON", typeof pageJson['pageConfig'])
        setHideFooter( true )
        setActionApiUrl("")
        setModalBodyText("")
        setModalBodyComponent("")
        if( pageJson?.pageConfig?.showConfirm ) {
            if( validateBulkIds && bulkSelectedIds.length <= 0 ) {
                setModalBodyText("Please select atleast one record for this action.")
                setHideFooter( true )
            } else {
                setModalBodyText( pageJson['pageConfig']['confirmMessage'] )
                setActionApiUrl( pageJson['pageConfig']['apiUrl'] )
                setHideFooter( false )
            }
        }
        else if( pageJson['pageType'] === "form" )
            setModalBodyComponent( <FormTemplate columnsConfig={ pageJson['pageConfig']['columnsConfig'] } apiUrl={ pageJson['pageConfig']['apiUrl'] } onSuccessCallback={ formSuccessCallback } /> )
        else if( pageJson['pageType'] === "list")
            setModalBodyComponent( <ListTemplate entity={ pageJson['entity'] } 
            pageTitle={ pageJson['pageTitle'] }
            pageConfig={ pageJson['pageConfig'] }
            /> )
    }

    const formSuccessCallback = ( response ) => {
        toast.success( response.message )
        setShowConfirmModal( false )
    }

    if( !actionsList.length ) {
        return null
    }

    return <>
        <ToastContainer />
        <li className="page-item dropdown">
            <button className="page-link" ref={buttonRef} data-toggle="dropdown" onClick={ (e) => { setShowList( !showList ) }} >
                Actions {expandIcon}
                <div style={{ display: showList ? "block" : "none" }} className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    
                    { actionsList.map( (actionItem, index ) => <React.Fragment key={"action" + index}><div className="dropdown-divider"></div>
                    <Link to={"/"} onClick={
                        (e) => {
                            e.preventDefault();
                            makeModalByJson( actionItem )
                        }
                    } className="dropdown-item" title="Sales Person">
                        { actionItem['pageTitle'] }
                    </Link></React.Fragment> ) }
                </div>
            </button>
            
        </li>
        { showConfirmModal && <ConfirmModal  modalTitle={ modalPageTitle } modalStateProp={ showConfirmModal } setModalStateProp={ setShowConfirmModal } modalSize={ "large" } 
        modalBodyComponent={ modalBodyComponent } modalBodyText = { modalBodyText } hideFooter = { hideFooter } actionApiUrl = { actionApiUrl }
        modalSuccessCallback={ ()=>{
            if ( typeof onSuccessCallback === "function") {
                onSuccessCallback()
            }
        } } actionApiPayload={ bulkSelectedIds .length > 0  ? { "bulkSelectedIds" : bulkSelectedIds } : { } }
        />}
    </>
}