import React from 'react'
import { callApiFromUrl, callGetApiFromUrl } from '../services/ApiService'
import { ToastContainer, toast } from 'react-toastify';
import "./ConfirmModal.css";

function ConfirmModal ( { actionApiUrl, modalStateProp, modalSize = "", setModalStateProp, modalTitle, modalBodyComponent, modalBodyText, hideFooter, modalSuccessCallback, modalCloseCallback, modalCancelCallback, actionApiPayload = {} }) {
    
    const [ formLoader, setFormLoader ] = React.useState( false )

    const handleCancelAction = (event) => {
        setModalStateProp(false)
        modalCancelCallback()
        event.preventDefault()
    }

    const setErrors = ( errors ) => {
        if( Object.keys( errors ).length ) {
            toast.error("There was an error with this request.")
        }
        setModalStateProp(false)
    }

    const setSuccessResponse = ( response = {} ) => {
        if( actionApiUrl && response ) {
            toast.success( response.message )
        }
        setTimeout( () =>{
            setModalStateProp(false)
            setFormLoader( false )
            modalSuccessCallback()
        }, 1000 )
    }

    const handleSuccessAction = (event) =>{ 
        if( actionApiPayload && actionApiUrl && !formLoader ) {
            callApiFromUrl( { apiUrl: actionApiUrl, params : actionApiPayload, setErrors: setErrors, setSuccessResponse : setSuccessResponse  } )
        } else if( actionApiUrl && !formLoader) {
            setFormLoader( true )
            callGetApiFromUrl( { apiUrl : actionApiUrl, setErrors: setErrors, setSuccessResponse : setSuccessResponse } )
        } else {
            setSuccessResponse()
        }
        
        event.preventDefault()
    }

    const handleCloseAction = (event) => {
        setModalStateProp(false)
        modalCloseCallback()
        event.preventDefault()
    }

    const isConfirmationModal = modalBodyComponent === "" && modalBodyText !== ""

    const modalStyle = modalStateProp ? { display: "block", backdropFilter: "blur(1px)"} : {}
    //TODO: added model center / top through prop
    const modalClass = ( isConfirmationModal ? "" : "" + ( modalSize !== "small" ? "modal-dialog-scrollable modal-dialog-top ": "") ) + ( modalSize === "xLarge" ? "modal-xl" : modalSize === "large" ? "modal-lg" : modalSize === "small" ? "modal-sm" : "modal-md" )

    return <div className="modal" style={ modalStyle } tabIndex="-1" role="dialog">
        <ToastContainer />
        <div className={ "modal-dialog " + modalClass } role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" style={{ textWrap: "pretty"}}>{ modalTitle }</h5>
                    <button className="close" onClick={ handleCancelAction }> <span aria-hidden="true">&times;</span> </button>
                </div>
                <div className="modal-body">
                    { modalBodyComponent !== "" && modalBodyComponent }
                    { modalBodyComponent === "" && <p>{ modalBodyText }</p> }
                </div>
                { !hideFooter && <div className="modal-footer">
                    <button className={ "btn btn-primary "+ ( formLoader ? "disabled" : "") } onClick={ handleSuccessAction }>
                    { formLoader && <div className="spinner-border spinner-border-sm mr-1" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> }
                        { isConfirmationModal ? "Confirm" :  "Save changes" }</button>
                    <button className="btn btn-secondary" data-dismiss="modal" onClick={ handleCloseAction }>Cancel</button>
                </div> }
            </div>
        </div>
    </div>
}

ConfirmModal.defaultProps = {
    modalStateProp: false,
    setModalStateProp: (event) => console.log("pass your parent state setter"),
    modalTitle : "Confirm Your Action",
    modalBodyText: "Are you sure ?",
    modalBodyComponent: "",
    hideFooter: false,
    modalSize: "small",
    modalSuccessCallback: (event) => console.log("success callback"),
    modalCloseCallback: (event) => console.log("close callback"),
    modalCancelCallback: (event) => console.log("cancel callback"),
    actionApiUrl : ""
}

export default ConfirmModal