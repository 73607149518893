import { useState } from "react"
import ButtonLoaderSpinner from "../../../common/ButtonLoaderSpinner"
import DisplayErrorsTemplate from "../../../common/DisplayErrorsTemplate"
import { apiUrl, baseUrl } from "../../../constants/app"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';

export default function ImportData( { setModalStateProp, successCallback, pageConfig } ) {
    const [loader, setLoader ] = useState(false)
    const [errors, setErrors ] = useState({})
    const [ title, setTitle ] = useState("")
    const [ selectedFile, setSelectedFile ] = useState()
    const [ progressBar, setProgressBar ] = useState(0)
    const [alertAttributes, setAlertAttributes] = useState([])

    const { pageTitle, sampleDownloadLink, apiUrl: importApiUrl } = pageConfig

    const handleFormSubmit = () => {
        if( loader )
            return true
        
        if( title === "") {
            setErrors( { "title" : "Please enter title."})
            return
        }

        if( !selectedFile ) {
            setErrors( { "fileUpload" : "Please select a file for upload."})
            return
        }
        setLoader( true )
        setAlertAttributes([])
        
        //append form data
        let formData = new FormData()
        formData.append("importFile", selectedFile )
        formData.append("title", title )
        
        //call API to upload data
        const bearerToken = localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : "";
        axios.post( apiUrl + importApiUrl, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                'Authorization' :  bearerToken
            },
            onUploadProgress: progressEvent => {
                setProgressBar( Math.round((100 * progressEvent.loaded) / progressEvent.total) )
            }
        }).then(function (response) {
            //console.log( response, response?.data, response?.data?.alertMsg , "success response ")
            setProgressBar(0)
            if( response?.data?.data?.alertMsg ) {
                setAlertAttributes( response.data.data.alertMsg )
            } else {
                toast.success( "Data has been imported successfully." )
                setTimeout( () =>{
                    setErrors({})
                    setSelectedFile()
                    setModalStateProp( false )
                    successCallback()
                })
            }
        })
        .catch(function ( axioError ) {
            setErrors( axioError.response.data.errors )
            setProgressBar(0)
        }).finally( function () {
            setLoader( false )
            //setSelectedFile()
        });
        
        //show progress bar
        //show success
    }

    //validation file upload rules
    const isValidFileUploaded=(file)=>{
        const validExtensions = ['xlsx','xls','csv']
        const fileExtension = file.name.split('.').pop()
        return validExtensions.includes(fileExtension)
    }

    const validateUploadedFile = ( e ) => {
        //reset file and errors state
        setSelectedFile()
        setErrors({})

        //check if a file has been selected
        if( e.target.files.length < 1 ) {
            setErrors( { "fileUpload" : "Please select a file for upload." } )
            return
        }
        //check if selected file is valid
        const file = e.target.files[0]
        if( !isValidFileUploaded( file ) ) {
            setErrors( { "fileUpload" : "Please select a valid excel file for upload." } )
            return
        }

        //set selected file
        setSelectedFile( file )
    }

    return <div className='card card-primary'>
            <ToastContainer />
            <div className="card-header">
                <h3 className="card-title">{ pageTitle }</h3>
            </div>
            
            <div className="card-body">
                <DisplayErrorsTemplate errors={ errors } />

                <div className="row">
                    <div className="col-12" >
                        <div className="form-group">
                            <label htmlFor="fileTitle">Title</label>
                            <input type="text" placeholder="File Title" className="form-control" id="fileTitle" value={ title } onChange={ (e) => setTitle( e.target.value ) } />
                        </div>

                        <div className="form-group">
                            <label htmlFor="uploadImportFile">File Upload</label>
                            <div className="input-group">
                                <div className="">
                                    <input type="file" id="uploadImportFile" onChange={ validateUploadedFile } />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            { sampleDownloadLink !== "" && <a href={ baseUrl + sampleDownloadLink } title="Download Sample" target="_blank">Download Sample</a> }
                        </div>
                    </div>
                </div>

                { progressBar !== 0 && <div class="progress">
                    <div class="progress-bar bg-primary progress-bar-striped" role="progressbar" style={{width: progressBar + "%"}}>
                        <span class="sr-only">00% Complete (success)</span>
                    </div>
                </div> }

                { alertAttributes.length > 0 && alertAttributes.map( (item, index) => { 
                    if( item.message !== undefined ) {
                        return <div key={ "alert-"+index } className= { "alert alert-default-"+item["className"] } role="alert" style={{whiteSpace: "pre-line"}}> { item.message } </div> 
                    } else if ( item.messages !== undefined && item.messages.length ) {
                            return <div key={ "alert-"+index } className= { "alert alert-default-"+item["className"] } role="alert" style={{whiteSpace: "pre-line"}}>
                                { item.messages.map( ( message, msgIndex) => <p key={ "alert-message-" + msgIndex }>{ message }</p>) }
                            </div> 
                    }
                    return null
                } ) }

            </div>

            <div className="card-footer">
                <button className={"btn btn-primary "+ ( loader ? "disabled" : "") } disabled={ loader || !selectedFile || !title } onClick={handleFormSubmit}>
                    { loader && <ButtonLoaderSpinner /> } Submit</button>
            </div>
        </div>
}